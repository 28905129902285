import template from './tickets.html';
import style from './tickets.less';
import seatsTemplate from './seats/seats.html';

export default angular.module('eventix.shop.tickets', [ ])
    .config(function($stateProvider) {
        $stateProvider.state('eventix.shop.tickets', {
            url: '/tickets?eventId',
            views: {
                'shop': {
                    controller: 'TicketsController as vm',
                    templateUrl: template
                }
            },
            resolve: /*@ngInject*/ {}
        });
    })
    .controller('TicketsController', function(UIMessages, shop, order, collapses, $state,
        $scope, $q, $http, $translate, Locales, Currencies, Ticket, $interval, $sce,
        $analytics, FB, $timeout, store, $filter, $location, SeatsIO, ShopAnalytics, $rootScope, $window) {
        var vm = this;
        vm.seatSelection = {
            templateUrl: seatsTemplate,
            open: false
        };


        // Mkae sure to scroll to top
        $window.scrollTo(0, 0);

        vm.init = init;
        function init(){
            document.getElementById("dropdown").classList.add('out');
            document.getElementById("dropdown").classList.remove('in');
        }
        init();


        vm.order = order;
        vm.messages = UIMessages;
        vm.locales = Locales;
        vm.currencies = Currencies;
        vm.shop = shop;
        vm.tickets = order.tickets;
        vm.events = order.events;
        vm.products = order.products;
        vm.collapses = collapses;
        vm.eventFilter = $state.params.eventId;
        vm.code = '';

        $rootScope.pageTitle = 'Tickets';

        let queryParams = $location.search();
        if (queryParams.coupon)
            vm.order.applyCoupon(queryParams.coupon);

        /** Iterate over events/tickets and check availability */
        vm.availableTickets = order.recalculateAvailableTickets();
        var checkAvailable = $interval(() => vm.availableTickets = order.recalculateAvailableTickets(), 10000);
        vm.availableEvents = _.reduce(order.events, (total, event) => total + (event.availableTickets ? 1 : 0), 0);
        vm.fourStepWizard = vm.shop.event_selection === 'enabled' || (vm.availableEvents >= 5 && vm.shop.event_selection === 'auto');
        $scope.$on('destroy', () => $interval.cancel(checkAvailable));

        if(vm.fourStepWizard && !vm.eventFilter)
            $state.go('eventix.shop.events', { shopId: vm.shop.guid });

        if(vm.fourStepWizard && vm.eventFilter)
            ShopAnalytics.impression(vm.tickets[vm.eventFilter]);
        else if(!vm.fourStepWizard)
            ShopAnalytics.impression();

        /**
         * Close seat selection dialog
         */
        vm.closeSeatSelection = function() {
            vm.seatSelection.open = false;
        };

        vm.openSeatSelection = function(ticket, eventId) {
            SeatsIO.open(ticket, eventId).then(() => vm.seatSelection.open = true);
        };

        vm.isParking   = isParking;
        function isParking(ticket){
            return false;
            let name = (ticket.name).toLowerCase();
            var i = name.indexOf('parking');
            return i === -1 ? false : true;
        }

        vm.isCamping   = isCamping;
        function isCamping(ticket){
            return false;
            let name = (ticket.name).toLowerCase();
            var i = name.indexOf('camping');
            return i === -1 ? false : true;
        }

        /** Goto next page in shop if user has selected 1+ ticket */
        vm.next = function() {
                $state.go('eventix.shop.foodAndDrinks', { shopId: shop.guid });
        };

        vm.reserve = function(ticketGuid, eventId){
            vm.order.reserveTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.addToCart(ticket);
        };

        vm.release = function(ticketGuid, eventId){
            vm.order.releaseTicket(ticketGuid, eventId);
            let ticket = Ticket.cached[ticketGuid];
            ShopAnalytics.removeFromCart(ticket);
        };
    }).name;
